import "./index.css";
import {Table} from "reactstrap";
import React from "react";
const data = [
    {
        role: "Lễ tân",
        name: "Cao Thanh Sơn",
        absent: "0",
        work: "7",
        color: "#00FF00",
        id: 1,
        nameSchedule: "Ca 1: 8h - 18h",
        startTime: "08:00",
        endTime: "18:00",
        username: "Cao Thanh Sơn",
        phone: "0865805699"
    },
    {
        role: "Phục vụ",
        name: "Sơn Tùng MTP",
        absent: "0",
        work: "7",
        color: "#964B00",
        id: 2,
        nameSchedule: "Ca 2: 11h - 21h",
        startTime: "11:00",
        endTime: "21:00",
        username: "Sơn Tùng MTP",
        phone: "0865805699"
    },
    {
        role: "Bảo vệ",
        name: "Nguyễn Hải Long",
        absent: "0",
        work: "7",
        color: "#FFFF00",
        id: 3,
        nameSchedule: "Full ca: 9h - 19h",
        startTime: "09:00",
        endTime: "19:00",
        username: "Nguyễn Hải Long",
        phone: "0865805699"
    },
    {
        role: "Điếu đóm",
        name: "Hoàng Đức Hiệp",
        absent: "0",
        work: "7",
        color: "#964B00",
        id: 2,
        nameSchedule: "Ca 2: 11h - 21h",
        startTime: "11:00:00",
        endTime: "21:00",
        username: "Hoàng Đức Hiệp",
        phone: "0865805699"
    },
    {
        role: "Quản lý",
        name: "Trần Quang Chiến",
        absent: "0",
        work: "7",
        color: "#00FF00",
        id: 3,
        nameSchedule: "Full ca: 9h - 19h",
        startTime: "09:00",
        endTime: "19:00",
        username: "Trần Quang Chiến",
        phone: "0865805699"
    },
    {
        role: "Giám đốc",
        name: "Jao JungNing",
        absent: "0",
        work: "7",
        color: "#964B00",
        id: 1,
        nameSchedule: "Ca 1: 8h - 18h",
        startTime: "08:00",
        endTime: "18:00",
        username: "Jao JungNing",
        phone: "0865805699"
    },
    {
        role: "Hành chính",
        name: "Kim Doek Huk",
        absent: "0",
        work: "7",
        color: "#FFFF00",
        id: 2,
        nameSchedule: "Ca 2: 11h - 21h",
        startTime: "11:00",
        endTime: "21:00",
        username: "Kim Doek Huk",
        phone: "0865805699"
    },
    {
        role: "Phó bảo vệ",
        name: "Lionel Ronaldo",
        absent: "0",
        work: "7",
        color: "#00FF00",
        id: 3,
        nameSchedule: "Full ca: 9h - 19h",
        startTime: "09:00",
        endTime: "19:00",
        username: "Lionel Ronaldo",
        phone: "0865805699"
    }
]
export default function DayoffTable() {
    return (
        <div className="table-info panel panel-default table-responsive table-schedule">
            <Table className="table table-bordered mb-3">
                <thead className="border">
                <tr>
                    <th style={{width: "200px"}}>Họ và tên</th>
                    <th>1</th>
                    <th>2</th>
                    <th>3</th>
                    <th>4</th>
                    <th>5</th>
                    <th>6</th>
                    <th>7</th>
                    <th>8</th>
                    <th>9</th>
                    <th>10</th>
                    <th>11</th>
                    <th>12</th>
                    <th>13</th>
                    <th>14</th>
                    <th>15</th>
                    <th>16</th>
                    <th>17</th>
                    <th>18</th>
                    <th>19</th>
                    <th>20</th>
                    <th>21</th>
                    <th>22</th>
                    <th>23</th>
                    <th>24</th>
                    <th>25</th>
                    <th>26</th>
                    <th>27</th>
                    <th>28</th>
                    <th>29</th>
                    <th>30</th>
                </tr>
                </thead>
                <tbody>
                {
                    data && data.map((item) => (
                        <tr>
                            <td>{item.name}</td>
                            <td style={{backgroundColor: `${item.color}`}}></td>
                            <td style={{backgroundColor: `${item.color}`}}></td>
                            <td style={{backgroundColor: `${item.color}`}}></td>
                            <td style={{backgroundColor: `${item.color}`}}></td>
                            <td style={{backgroundColor: `${item.color}`}}></td>
                            <td style={{backgroundColor: `${item.color}`}}></td>
                            <td style={{backgroundColor: `${item.color}`}}></td>
                            <td style={{backgroundColor: `${item.color}`}}></td>
                            <td style={{backgroundColor: `${item.color}`}}></td>
                            <td style={{backgroundColor: `${item.color}`}}></td>
                            <td style={{backgroundColor: `${item.color}`}}></td>
                            <td style={{backgroundColor: `${item.color}`}}></td>
                            <td style={{backgroundColor: `${item.color}`}}></td>
                            <td style={{backgroundColor: `${item.color}`}}></td>
                            <td style={{backgroundColor: `${item.color}`}}></td>
                            <td style={{backgroundColor: `${item.color}`}}></td>
                            <td style={{backgroundColor: `${item.color}`}}></td>
                            <td style={{backgroundColor: `${item.color}`}}></td>
                            <td style={{backgroundColor: `${item.color}`}}></td>
                            <td style={{backgroundColor: `${item.color}`}}></td>
                            <td style={{backgroundColor: `${item.color}`}}></td>
                            <td style={{backgroundColor: `${item.color}`}}></td>
                            <td style={{backgroundColor: `${item.color}`}}></td>
                            <td style={{backgroundColor: `${item.color}`}}></td>
                            <td style={{backgroundColor: `${item.color}`}}></td>
                            <td style={{backgroundColor: `${item.color}`}}></td>
                            <td style={{backgroundColor: `${item.color}`}}></td>
                            <td style={{backgroundColor: `${item.color}`}}></td>
                            <td style={{backgroundColor: `${item.color}`}}></td>
                            <td style={{backgroundColor: `${item.color}`}}></td>
                        </tr>
                    ))
                }
                </tbody>
            </Table>
        </div>
    )
}