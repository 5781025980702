import "./index.css";
import React, {useState} from "react";
import {Button, Col, Input, Label, Row} from "reactstrap";
import {Link} from "react-router-dom";

export default function ScheduleNewWork() {
    const [status, setStatus] = useState(true);
    const [employeeName, setEmployeeName] = useState(null);
    const [isCheck , setIsCheck] = useState(false);
    return (
        <React.Fragment>
            <div className="page-content">
                <Row className="d-flex flex-row justify-content-between align-items-center">
                    <Col>
                        <div className="page-title-box d-flex align-items-center justify-content-between">
                            <h4 className="page-title mb-0 font-size-18">CA LÀM VIỆC</h4>
                        </div>
                    </Col>
                    <Col className="text-white d-flex flex-row justify-content-end">
                        <b className="h-auto">Ca làm việc </b>
                        <p className="mb-0"> / Thêm mới</p>
                    </Col>
                </Row>
                <div className="card-container">
                    <h4 className="heading">Chi tiết</h4>
                    <div>
                        <div className="d-flex flex-column">
                            <Label htmlFor="id" className="label">Mã ca</Label>
                            <Input id="id" name="id" className="input-edit-info disabled" type="text" value="W00001"
                                   disabled required/>
                        </div>
                        <div className="d-flex flex-column">
                            <Label htmlFor="employeeName" className="label">Tên ca làm việc</Label>
                            <Input invalid={isCheck} id="employeeName" name="employeeName" placeholder="Nhập tên ca làm việc"
                                   className="input-edit-info" type="text"
                                   onChange={(e) => setEmployeeName(e.target.value)} value={employeeName} required/>
                            {isCheck && <p className="message-err">
                                Chưa nhập tên ca làm việc
                            </p>}
                        </div>
                        <div className="d-flex flex-column">
                            <Label htmlFor="avatar" className="label">Giờ bắt đầu</Label>
                            <Input invalid={isCheck} type="time" className="input-time" />
                            {isCheck && <p className="message-err">
                                Chưa chọn giờ bắt đầu
                            </p>}
                        </div>
                        <div className="d-flex flex-column">
                            <Label htmlFor="birthDay" className="label">Giờ kết thúc</Label>
                            <Input invalid={isCheck} type="time" className="input-time" />
                            {isCheck && <p className="message-err">
                                Chưa chọn giờ kết thúc
                            </p>}
                        </div>
                        <div>
                            <Label htmlFor="status" className="label">Trạng thái</Label>
                            <div className="d-flex flex-row align-items-center">
                                <div className="form-switch me-1">
                                    <input id="status" className="form-check-input" name="status" type="checkbox"
                                           role="switch" id="switchCheckChecked" checked={status}
                                           onChange={() => setStatus(!status)} required/>
                                </div>
                                {
                                    status ?
                                        (
                                            <span className="text-success">
                                                (Hoạt động)
                                            </span>
                                        ) : (
                                            <span className="text-danger">
                                                (Không hoạt động)
                                            </span>
                                        )
                                }
                            </div>
                            <div className="">
                                <Label htmlFor="color" className="label">Màu</Label>
                                <Input id="color" className="color-picker" type="color"/>
                            </div>
                        </div>
                    </div>
                    <div className="mt-3 d-flex flex-row justify-content-end">
                        <div className="mt-3 d-flex flex-row justify-content-end">
                            <Button
                                color="success"
                                outline
                                className="me-2"
                            >
                                <Link to="/schedule/details">
                                    Thêm mới
                                </Link>
                            </Button>
                            <Button
                                color="danger"
                                outline
                            >
                                <Link to="/dashboard">
                                    Huỷ bỏ
                                </Link>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}