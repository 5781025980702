import "./index.css";
import {Pagination, PaginationItem, PaginationLink} from "reactstrap";
import EmployeeInfo from "./employee-info";

const employee = [
    {
        id: "NV987654",
        name: "Lê Thị D",
        role: "Quản lý",
        subRole: "Quản lý chi nhánh",
        branch: "Chi nhánh CN0001"
    },
    {
        id: "NV987653",
        name: "Lê Lục Bình",
        role: "Quản lý",
        subRole: "Quản lý chi nhánh",
        branch: "Chi nhánh CN0002"
    },
    {
        id: "NV987657",
        name: "Lê Văn H",
        role: "Quản lý",
        subRole: "Quản lý chi nhánh",
        branch: "Chi nhánh CN0008"
    },
    {
        id: "NV987651",
        name: "Lê Thị D",
        role: "Quản lý",
        subRole: "Quản lý chi nhánh",
        branch: "Chi nhánh CN0001"
    },
    {
        id: "NV987659",
        name: "Lê Thị D",
        role: "Quản lý",
        subRole: "Quản lý chi nhánh",
        branch: "Chi nhánh CN0001"
    },
    {
        id: "NV987658",
        name: "Lê Thị D",
        role: "Quản lý",
        subRole: "Quản lý chi nhánh",
        branch: "Chi nhánh CN0004"
    }
]

export default function ListEmployee(){
    return (
        <div className="table-info panel panel-default">
            <table className="table table-striped table-bordered table-responsive mb-3">
                <thead className="border">
                <tr>
                    <th style={{width: "40%"}}>Mã nhân viên</th>
                    <th>Tên nhân viên</th>
                </tr>
                </thead>
                <tbody>
                    {
                        employee && employee.map((item) => (
                            <EmployeeInfo
                                id={item.id}
                                name={item.name}
                                role={item.role}
                                subRole={item.subRole}
                                branch={item.branch}
                            />
                        ))
                    }
                </tbody>
            </table>
            <Pagination
                aria-label="Page navigation example"
                size="sm"
            >
                <PaginationItem>
                    <PaginationLink
                        href="#"
                        previous
                    >
                        Trước
                    </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                    <PaginationLink href="#">
                        1
                    </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                    <PaginationLink href="#">
                        2
                    </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                    <PaginationLink href="#">
                        3
                    </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                    <PaginationLink
                        href="#"
                        next
                    >
                        Sau
                    </PaginationLink>
                </PaginationItem>
            </Pagination>
        </div>
    )
}