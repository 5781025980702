import PropTypes from "prop-types"
import React from "react"
import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"
import "./Sidebar.css";
//i18n
import { withTranslation } from "react-i18next"
import SidebarContent from "./SidebarContent"


const Sidebar = props => {
    const toggleMenuSidebar = ()=> {
        var body = document.body;
        if (window.screen.width <= 768) {
            body.classList.toggle("sidebar-enable");
        } else {
            body.classList.toggle("vertical-collpsed");
            body.classList.toggle("sidebar-enable");
        }
    }
  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="overlay-modal" onClick={toggleMenuSidebar}></div>
        <div className="h-100 sidebar-content">
            <div data-simplebar className="h-100">
                {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
            </div>
        </div>
      </div>
    </React.Fragment>
  )
}

Sidebar.propTypes = {
        type: PropTypes.string,
}

const mapStatetoProps = state => {
  return {
        layout: state.Layout,
  }
}
export default connect(
  mapStatetoProps,
      {}
)(withRouter(withTranslation()(Sidebar)))