import React, {useState} from "react";
import {Input, Label} from "reactstrap";
import "./index.css";
const schedule = [
    {
        id: 1,
        name: "Nửa ca - buổi sáng",
        color: "#964B00"
    },
    {
        id: 2,
        name: "Nửa ca - buổi chiều",
        color: "#FFFF00"
    },
    {
        id: 3,
        name: "Full ca",
        color: "#00FF00"
    }
]
export default function DayoffCard(props){
    const {id, handleDeleteDayOff} = props;
    const [dayoff, setDayoff] = useState();
    const [reason, setReason] = useState();
    const [isCheck , setIsCheck] = useState(false);

    return (
        <div className="card-container mb-3">
            <div className="d-flex flex-row justify-content-between align-items-center">
                <h4 className="heading">Đăng ký</h4>
                <span onClick={() => {
                    handleDeleteDayOff(id);
                }}>
                    <i className="bx bxs-x-circle text-danger fs-3"></i>
                </span>
            </div>
            <div className="d-flex flex-column">
                <Label htmlFor="dayoff" className="label">Ngày nghỉ</Label>
                <Input
                        invalid={isCheck}
                        id="dayoff"
                        className="input-edit-info form-control"
                        name="dayoff"
                        placeholder="mm/dd/yyyy"
                        type="date"
                        style={{width: "150px"}}
                        value={dayoff}
                        onChange={(e) => setDayoff(e.target.value)}
                />
                {isCheck && <p className="message-err">
                    Chưa chọn ngày nghỉ
                </p>}
            </div>
            <div className="d-flex flex-column">
                <Label htmlFor="birthDay" className="label">Ca nghỉ</Label>
                <select className="select-wrapper select-container" name="" id="">
                    <option value="all">Tất cả các ca</option>
                    {
                        schedule.map((item) => {
                            return (
                                <>
                                    <option value={item.name} key={item.name}>
                                        {item.name}
                                    </option>
                                </>
                            )
                        })
                    }
                </select>
            </div>
            <div className="d-flex flex-column">
                <Label htmlFor="address" className="label">Lý do nghỉ</Label>
                <Input
                    invalid={isCheck}
                    id="address"
                    className="input-edit-info input-textarea"
                    name="address"
                    type="textarea"
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                    placeholder="Nhập lý do nghỉ"
                />
                {isCheck && <p className="message-err">
                    Chưa nhập lý do nghỉ
                </p>}
            </div>
        </div>
    )
}