import {UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem} from "reactstrap";
import {Link} from "react-router-dom";
import "./index.css";
export default function BranchDetails(props){
    const {id, name, manager, status} = props;
    const  toggleDetails = (detailId) => {
        const detailRow = document.getElementsByClassName(detailId);
        for(var i = 0 ; i < detailRow.length ; ++i){
            if (detailRow[i].style.display === "none" || detailRow[i].style.display === "") {
                detailRow[i].style.display = "table-row"; // Show the details
            } else {
                detailRow[i].style.display = "none"; // Hide the details
            }
        }
    }
    return (
        <>
            <tr className="clickable" onClick={() => {
                toggleDetails(id);
            }}>
                <td className="d-flex flex-row">
                    <button className="btn btn-default btn-sm pt-0 pb-0">
                        <i className="bx bxs-plus-circle h5 mb-0 text-primary"></i>
                    </button>
                    <span className="pb-1">
                        {id}
                    </span>
                </td>
                <td>{name}</td>
            </tr>
            <tr className={`collapse ${id}`}>
                <td>Họ tên quản lý</td>
                <td>
                    <i>{manager}</i>
                </td>
            </tr>
            <tr className={`collapse ${id}`}>
                <td>Trạng thái</td>
                <td>
                    <div className="form-switch ps-0">
                        { status ? <i className="text-success">Hoạt động</i> : <i className="text-danger">Không hoạt động</i> }
                    </div>
                </td>
            </tr>
            <tr className={`collapse ${id}`}>
                <td>Tác vụ</td>
                <td>
                    <UncontrolledDropdown
                        className="me-2"
                        direction="end"
                    >
                        <DropdownToggle
                            caret
                            className="dropdown-part"
                        >
                            <i className="bx bx-dots-horizontal-rounded option-icon"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem>
                                <Link to="/branch/details">
                                    Chi tiết
                                </Link>
                            </DropdownItem>
                            <DropdownItem>
                                <Link to="/branch/edit">
                                    Chỉnh sửa
                                </Link>
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </td>
            </tr>
        </>
    )
}