import {Pagination, PaginationItem, PaginationLink} from "reactstrap";
import ScheduleInfo from "./schedule-info";

const employee = [
    {
        id: "W0001",
        name: "Ca làm 1",
        startTime: "08:00",
        endTime: "18:00",
        status: "Hoạt động",
        color: "greenyellow"
    },
    {
        id: "W0002",
        name: "Ca làm 1",
        startTime: "08:00",
        endTime: "18:00",
        status: "Hoạt động",
        color: "greenyellow"
    },
    {
        id: "W0003",
        name: "Ca làm 1",
        startTime: "08:00",
        endTime: "18:00",
        status: "Hoạt động",
        color: "greenyellow"
    },
    {
        id: "W0004",
        name: "Ca làm 1",
        startTime: "08:00",
        endTime: "18:00",
        status: "Hoạt động",
        color: "greenyellow"
    },
    {
        id: "W0005",
        name: "Ca làm 1",
        startTime: "08:00",
        endTime: "18:00",
        status: "Hoạt động",
        color: "greenyellow"
    },
    {
        id: "W0006",
        name: "Ca làm 1",
        startTime: "08:00",
        endTime: "18:00",
        status: "Hoạt động",
        color: "greenyellow"
    },
]

export default function ListSchedule(){
    return (
        <div className="table-info panel panel-default">
            <table className="table table-striped table-bordered table-responsive mb-3">
                <thead className="border">
                <tr>
                    <th style={{width: "40%"}}>Mã ca </th>
                    <th>Tên ca làm việc</th>
                </tr>
                </thead>
                <tbody>
                {
                    employee && employee.map((item) => (
                        <ScheduleInfo
                            id={item.id}
                            name={item.name}
                            startTime={item.startTime}
                            endTime={item.endTime}
                            status={item.status}
                            color={item.color}
                        />
                    ))
                }
                </tbody>
            </table>
            <Pagination
                aria-label="Page navigation example"
                size="sm"
            >
                <PaginationItem>
                    <PaginationLink
                        href="#"
                        previous
                    >
                        Trước
                    </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                    <PaginationLink href="#">
                        1
                    </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                    <PaginationLink href="#">
                        2
                    </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                    <PaginationLink href="#">
                        3
                    </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                    <PaginationLink
                        href="#"
                        next
                    >
                        Sau
                    </PaginationLink>
                </PaginationItem>
            </Pagination>
        </div>
    )
}