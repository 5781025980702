import "./index.css";
import {Col, Row} from "reactstrap";
import React from "react";
import DayoffTable from "./components/dayoff-table";
const schedule = [
    {
        id: 1,
        name: "Ca 1: 8h - 18h",
        color: "#964B00"
    },
    {
        id: 2,
        name: "Ca 2: 11h - 21h",
        color: "#FFFF00"
    },
    {
        id: 3,
        name: "Full ca: 9h - 19h",
        color: "#00FF00"
    }
]
export default function DayOffList(){
    return (
        <React.Fragment>
            <div className="page-content">
                <Row className="d-flex flex-row justify-content-between align-items-center">
                    <Col>
                        <div className="page-title-box d-flex align-items-center justify-content-between">
                            <h4 className="page-title mb-0 font-size-18">NGÀY NGHỈ</h4>
                        </div>
                    </Col>
                    <Col className="text-white d-flex flex-row justify-content-end">
                        <b className="h-auto">Ngày nghỉ </b>
                        <p className="mb-0"> / Danh sách</p>
                    </Col>
                </Row>
                <div className="card-container">
                    <h5 className="heading">Danh sách</h5>
                    <div className="filter d-flex flex-column align-items-between mb-4">
                        <div className="filter d-flex flex-row align-items-center mb-3">
                            <div className="">
                                <span>Năm  </span>
                                <select className="select-wrapper" name="" id="">
                                    <option value="">2024</option>
                                    <option value="">2025</option>
                                    <option value="">2026</option>
                                </select>
                            </div>
                            <div className="ms-3">
                                <span>Tháng  </span>
                                <select className="select-wrapper" name="" id="">
                                    <option value="">1</option>
                                    <option value="">2</option>
                                    <option value="">3</option>
                                </select>
                            </div>
                        </div>
                        <div className="filter d-flex flex-row justify-content-between align-items-center mb-3">
                            <div className="w-100 d-flex">
                                <span>Chi nhánh </span>
                                <select className="select-wrapper w-75 ms-3" name="" id="">
                                    <option value="">Tất cả các chi nhánh</option>
                                    <option value="">CN0001-Chi nhánh CN0001</option>
                                    <option value="">CN0009-Chi nhánh CN0009</option>
                                    <option value="">CN0006-Chi nhánh CN0006</option>
                                    <option value="">CN0008-Chi nhánh CN0008</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <DayoffTable />
                    <div>
                        {
                            schedule.map((item) => (
                                <div className="d-flex flex-row align-items-center mb-3">
                                    <div className="item-note me-2" style={{backgroundColor: `${item.color}`}}></div>
                                    <div>{item.name}</div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}