import "./index.css";
import {Button, Col, Input, Label, Row} from "reactstrap";
import toastr from "toastr";
import {Link, useLocation} from "react-router-dom";
import React, {useEffect, useState} from "react";
import avatarImage from "../../../../assets/images/avatar.png";
import imageFront from "../../../../assets/images/front.png";
import imageBehind from "../../../../assets/images/behind.png";
import {formatDate} from "../../../../utils";

export default function EmployeeDetails(){
    const [branchName , setBranchName] = useState("CN0001-Chi nhánh CN0001");
    const [address, setAddress] = useState("Đường Giải Phóng, quận Hai Bà Trưng, thành phố Hà Nội");
    const [username, setUsername] = useState("nguyenvana");
    const [status, setStatus] = useState(true);
    const [birthDay , setBirthDay] = useState("31/01/1990");
    const [isEdit, setIsEdit] = useState(false);
    const [employeeName, setEmployeeName] = useState("Kylian Mbappe");
    const [userId, setUserId] = useState("001234567890");
    const [phone, setPhone] = useState("0123456789");
    const [role, setRole] = useState("Quản lý");
    const [subRole , setSubRole] = useState("Quản lý chi nhánh");
    const [startDate, setStartDate] = useState("08/09/2016");
    const [frontCard , setFrontCard] = useState(imageFront);
    const [behindCard , setBehindCard] = useState(imageBehind);
    const [avatar, setAvatar] = useState(avatarImage);
    const {pathname} = useLocation();
    useEffect(() => {
        pathname === '/employee/details' ? setIsEdit(false) : setIsEdit(true);
    },[pathname])
    return (
        <React.Fragment>
            <div className="page-content">
                <Row className="d-flex flex-row justify-content-between align-items-center">
                    <Col>
                        <div className="page-title-box d-flex align-items-center justify-content-between">
                            <h4 className="page-title mb-0 font-size-18">NHÂN VIÊN</h4>
                        </div>
                    </Col>
                    <Col className="text-white d-flex flex-row justify-content-end">
                        <b className="h-auto">Nhân viên </b>
                        <p className="mb-0"> / Chi tiết</p>
                    </Col>
                </Row>
                <div className="card-container">
                    <h4 className="heading">Chi tiết</h4>
                    <div>
                        <div className="d-flex flex-column">
                            <Label htmlFor="id" className="label">Mã nhân viên</Label>
                            {
                                isEdit ? <Input id="id"  name="id" className="input-edit-info disabled" type="text" value="NV123456" disabled required/> : <p className="info">NV123456</p>
                            }
                        </div>
                        <div className="d-flex flex-column">
                            <Label htmlFor="employeeName" className="label">Tên nhân viên</Label>
                            {
                                isEdit ? <Input id="employeeName" name="employeeName" className="input-edit-info" type="text" onChange={(e) => setEmployeeName(e.target.value)} value={employeeName} required/> : <p className="info">{employeeName}</p>
                            }
                        </div>
                        <div className="d-flex flex-column">
                            <Label htmlFor="avatar" className="label">Ảnh đại diện</Label>
                            <img src={avatar} alt="avatar" className="avatar"/>
                            {
                                isEdit &&
                                <input
                                    type="file"
                                    accept="image/*"
                                    id="avatarImg"
                                    className="form-control mt-2 form-control-sm"
                                    onChange={(e) => {
                                        const [file] = e.target.files;
                                        if(file){
                                            const url = URL.createObjectURL(file);
                                            setAvatar(url);
                                        }
                                    }}
                                />
                            }
                        </div>
                        <div className="d-flex flex-column">
                            <Label htmlFor="birthDay" className="label">Ngày sinh</Label>
                            {
                                isEdit ? <Input
                                            id="birthDay"
                                            className="input-edit-info form-control"
                                            name="birthDay"
                                            type="date"
                                            value={birthDay}
                                            onChange={(e) => setBirthDay(e.target.value)}
                                            /> : <p className="info">{birthDay}</p>
                            }
                        </div>
                        <div className="d-flex flex-column">
                            <Label htmlFor="userId" className="label">Số CCCD</Label>
                            {
                                isEdit ? <Input id="userId" className="input-edit-info" name="userId" type="text" value={userId} onChange={(e) => setUserId(e.target.value)}/> : <p className="info">{userId}</p>
                            }
                        </div>
                        <div className="d-flex flex-column">
                            <Label htmlFor="userIdCard" className="label">Ảnh chụp CCCD</Label>
                            <span className="sub-label">Mặt trước:</span>
                            <img src={frontCard} alt="abc"/>
                            {
                                isEdit && <input
                                            className="form-control mt-2 form-control-sm"
                                            id="formFileSm"
                                            type="file"
                                            accept="image/*"
                                            onChange={(e) => {
                                                const [file] = e.target.files;
                                                if(file){
                                                    const url = URL.createObjectURL(file);
                                                    setFrontCard(url);
                                                }
                                            }}
                                          />
                            }
                            <span className="sub-label mt-2 object-fit-cover">Mặt sau:</span>
                            <img src={behindCard} alt="abc"/>
                            {
                                isEdit && <input
                                            className="form-control mt-2 form-control-sm"
                                            id="formFileSm"
                                            type="file"
                                            accept="image/*"
                                            onChange={(e) => {
                                                const [file] = e.target.files;
                                                if(file){
                                                    const url = URL.createObjectURL(file);
                                                    setBehindCard(url);
                                                }
                                            }}
                                          />
                            }
                        </div>
                        <div className="d-flex flex-column">
                            <Label htmlFor="phone" className="label">Số điện thoại</Label>
                            {
                                isEdit ? <Input id="phone" className="input-edit-info" name="phone" type="text" value={phone} onChange={(e) => setPhone(e.target.value)}/> : <p className="info">{phone}</p>
                            }
                        </div>
                        <div className="d-flex flex-column">
                            <Label htmlFor="address" className="label">Quê quán</Label>
                            {
                                isEdit ? <Input id="address" className="input-edit-info input-textarea" name="address" type="textarea" value={address} onChange={(e) => setAddress(e.target.value)}/> : <p className="info">{address}</p>
                            }
                        </div>
                        <div className="d-flex flex-column">
                            <Label htmlFor="role" className="label">Chức vụ</Label>
                            {
                                isEdit ?
                                <select className="form-select" aria-label="Default select example" onChange={(e) => setRole(e.target.value)}>
                                    <option value="Quản lý">Quản lý</option>
                                    <option value="Nhân viên">Nhân viên</option>
                                    <option value="Lễ tân">Lễ tân</option>
                                    <option value="Bảo vệ">Bảo vệ</option>
                                </select> : <p className="info">{role}</p>
                            }
                        </div>
                        <div className="d-flex flex-column">
                            <Label htmlFor="subRole" className="label">Vị trí</Label>
                            {
                                isEdit ? <Input id="subRole" className="input-edit-info" name="subRole" type="text" value={subRole} onChange={(e) => setSubRole(e.target.value)}/> : <p className="info">{subRole}</p>
                            }
                        </div>
                        <div className="d-flex flex-column">
                            <Label htmlFor="branchName" className="label">Chi nhánh</Label>
                            {
                                isEdit ? <select className="form-select" aria-label="Default select example" onChange={(e) => setBranchName(e.target.value)}>
                                    <option value="Chi nhánh CN0001">Chi nhánh CN0001</option>
                                    <option value="Chi nhánh CN0001">Chi nhánh CN0002</option>
                                    <option value="Chi nhánh CN0001">Chi nhánh CN0003</option>
                                </select> : <p className="info">{branchName}</p>
                            }
                        </div>
                        <div className="d-flex flex-column">
                            <Label htmlFor="startDate" className="label">Ngày bắt đầu làm việc</Label>
                            {
                                isEdit ? <Input id="startDate" className="input-edit-info form-control" name="startDate" type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)}/> : <p className="info">{startDate}</p>
                            }
                        </div>
                        <div className="d-flex flex-column">
                            <Label htmlFor="username" className="label">Tên tài khoản đăng nhập</Label>
                            {
                                isEdit ? <Input id="username" className="input-edit-info" name="username" type="text" value={username} onChange={(e) => setUsername(e.target.value)}/> : <p className="info">{username}</p>
                            }
                        </div>
                        {
                            isEdit &&
                            <div className="d-flex flex-column">
                                <Label htmlFor="password" className="label">Thay đổi mật khẩu</Label>
                                <Input id="password" className="input-edit-info" name="password" type="text" placeholder="Nhập mật khẩu mới"/>
                            </div>
                        }
                        <div>
                            <Label htmlFor="status" className="label">Trạng thái</Label>
                            <div className="d-flex flex-row align-items-center">
                                {
                                    isEdit ? <div className="form-switch me-1">
                                        <input id="status" className="form-check-input" name="status" type="checkbox" role="switch" id="switchCheckChecked" checked={status} onChange={() => setStatus(!status)} required/>
                                    </div> : <></>
                                }
                                {
                                    status ?
                                        (
                                            <span className="text-success">
                                                Đang làm
                                            </span>
                                        ) : (
                                            <span className="text-danger">
                                                Đã nghỉ
                                            </span>
                                        )
                                }

                            </div>
                        </div>
                    </div>
                    <div className="mt-3 d-flex flex-row justify-content-end">
                        {
                            isEdit ? (
                                <>
                                    <Button
                                        color="success"
                                        outline
                                        className="me-2"
                                        onClick={() => {
                                            toastr.success("Thay đổi thông tin thành công!");
                                            setIsEdit(false);
                                        }}
                                    >
                                        Lưu lại
                                    </Button>
                                    <Button
                                        color="danger"
                                        outline
                                        onClick={() => setIsEdit(false)}
                                    >
                                        Huỷ bỏ
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <Button
                                        color="warning"
                                        outline
                                        className="me-2"
                                        onClick={() => setIsEdit(true)}
                                    >
                                        Chỉnh sửa
                                    </Button>
                                    <Button
                                        color="primary"
                                        outline
                                    >
                                        <Link to="/employee/list">
                                            Trở về
                                        </Link>
                                    </Button>
                                </>
                            )
                        }
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}