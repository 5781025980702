import avatar2 from "../../../../assets/images/users/avatar-2.jpg";
import React from "react";

export default function Overview(){
    return (
        <div className="card-info mt-3">
            <h4 className="fs-5 fw-normal">Tổng quan</h4>
            <div>
                <ul>
                    <li>Số chi nhánh : 8</li>
                    <li>Số nhân viên : 100</li>
                </ul>
            </div>
        </div>
    )
}