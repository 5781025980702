import {Button, Col, FormText, Input, Label, Row} from "reactstrap";
import React, {useEffect, useState} from "react";
import toastr from "toastr";
import "./index.css"
import {Link, useLocation} from "react-router-dom";
export default function BranchDetails(){
    const [branchName , setBranchName] = useState("Chi nhánh CN0001");
    const [address, setAddress] = useState("Đường Giải Phóng, quận Hai Bà Trưng, thành phố Hà Nội");
    const [username, setUsername] = useState("nguyenvana");
    const [status, setStatus] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isCheck, setIsCheck] = useState(false);
    const {pathname} = useLocation();
    useEffect(() => {
        pathname === '/branch/details' ? setIsEdit(false) : setIsEdit(true);
    },[pathname])
    return (
        <React.Fragment>
            <div className="page-content">
                <Row className="d-flex flex-row justify-content-between align-items-center">
                    <Col>
                        <div className="page-title-box d-flex align-items-center justify-content-between">
                            <h4 className="page-title mb-0 font-size-18">CHI NHÁNH</h4>
                        </div>
                    </Col>
                    <Col className="text-white d-flex flex-row justify-content-end">
                        <b className="h-auto">Chi nhánh </b>
                        <p className="mb-0"> / Chi tiết</p>
                    </Col>
                </Row>
                <div className="card-container">
                    <h4 className="heading">Chi tiết</h4>
                    <div>
                        <div className="d-flex flex-column ">
                            <Label htmlFor="id" className="label asterisk">Mã chi nhánh</Label>
                            {
                                isEdit ? <Input id="id"  name="id" className="input-edit-info disabled" type="text" value="CN0001" disabled required/> : <p className="info">CN0001</p>
                            }
                        </div>
                        <div className="d-flex flex-column">
                            <Label htmlFor="branchName" className="label asterisk">Tên chi nhánh</Label>
                            {
                                isEdit ? <Input id="branchName" name="branchName" className="input-edit-info" type="text" onChange={(e) => setBranchName(e.target.value)} value={branchName} required/> : <p className="info">{branchName}</p>
                            }
                        </div>
                        <div className="d-flex flex-column">
                            <Label htmlFor="address" className="label asterisk">Địa chỉ</Label>
                            {
                                isEdit ? <Input id="address" name="address" className="input-edit-info input-textarea" type="textarea" onChange={(e) => setAddress(e.target.value)} value={address} required/> : <p className="info">{address}</p>
                            }
                        </div>
                        <div className="d-flex flex-column">
                            <Label htmlFor="username" className="label">Tài khoản quản lý</Label>
                            {
                                isEdit ? (
                                    <div className="d-flex flex-row justify-content-between align-items-center">
                                        <Input id="username" className="input-edit-info" name="username" type="text" onChange={(e) => setUsername(e.target.value)} value={username}/>
                                        <Button
                                            className="btn-test"
                                            color="info"
                                            outline
                                        >
                                            <span>
                                                Kiểm tra
                                            </span>
                                        </Button>
                                    </div>
                                ) : <p className="info">{username}</p>
                            }
                        </div>
                        <div className="d-flex flex-column">
                            <Label htmlFor="manager" className="label">Họ tên quản lý</Label>
                            {
                                isEdit ? <>
                                    <Input invalid={isCheck} id="manager" style={isCheck ? {} : {border: "none !important"}} className="input-edit-info disabled" aria-errormessage="err" name="manager" type="text" value="Nguyễn Văn A" disabled/>
                                    {isCheck && <p className="message-err">
                                        Chưa kiểm tra tên đăng nhập
                                    </p>}
                                </> : <p className="info">Nguyễn Văn A</p>
                            }
                        </div>
                        <div className="d-flex flex-column">
                            <Label htmlFor="phone" className="label">Số điện thoại quản lý</Label>
                            {
                                isEdit ? <>
                                    <Input invalid={isCheck} id="phone" style={isCheck ? {} : {border: "none !important"}} className="input-edit-info disabled" name="phone" aria-errormessage="err" type="text" value="0987654321" disabled/>
                                    {isCheck && <p className="message-err">
                                        Chưa kiểm tra tên đăng nhập
                                    </p>}
                                </> : <p className="info">0987654321</p>
                            }
                        </div>
                        <div>
                            <Label htmlFor="status" className="label asterisk">Trạng thái</Label>
                            <div className="d-flex flex-row align-items-center">
                                {
                                    isEdit ? <div className="form-switch me-1">
                                        <input id="status" className="form-check-input" name="status" type="checkbox" role="switch" id="switchCheckChecked" checked={status} onChange={() => setStatus(!status)} required/>
                                    </div> : <></>
                                }
                                {
                                    status ?
                                        (
                                            <span>
                                                Hoạt động
                                            </span>
                                        ) : (
                                            <span>
                                                Không hoạt động
                                            </span>
                                        )
                                }

                            </div>
                        </div>
                    </div>
                    <div className="mt-3 d-flex flex-row justify-content-end">
                        {
                            isEdit ? (
                                <>
                                    <Button
                                        color="success"
                                        outline
                                        className="me-2"
                                        onClick={() => {
                                            toastr.success("Thay đổi thông tin thành công!");
                                            setIsEdit(false);
                                        }}
                                    >
                                        Lưu lại
                                    </Button>
                                    <Button
                                        color="danger"
                                        outline
                                        onClick={() => setIsEdit(false)}
                                    >
                                        Huỷ bỏ
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <Button
                                        color="warning"
                                        outline
                                        className="me-2"
                                        onClick={() => setIsEdit(true)}
                                    >
                                        Chỉnh sửa
                                    </Button>
                                    <Button
                                        color="primary"
                                        outline
                                    >
                                        <Link to="/branch">
                                            Trở về
                                        </Link>
                                    </Button>
                                </>
                            )
                        }
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}