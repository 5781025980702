import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import "./index.css";

export default function ScheduleInfo(props){
    const {id, name,  startTime, endTime, status , color} = props;
    const [detailRow, setDetailRow] = useState();
    const  toggleDetails = (detailId) => {
        for(var i = 0 ; i < detailRow.length ; ++i){
            if (detailRow[i].style.display === "none" || detailRow[i].style.display === "") {
                detailRow[i].style.display = "table-row"; // Show the details
            } else {
                detailRow[i].style.display = "none"; // Hide the details
            }
        }
    }
    useEffect(() => {
        const detail = document.getElementsByClassName(id);
        setDetailRow(detail);
    },[])
    return (
        <>
            <tr className="clickable" onClick={() => {
                toggleDetails(id);
            }}>
                <td className="d-flex flex-row">
                    <button className="btn btn-default btn-sm pt-0 pb-0">
                        <i className="bx bxs-plus-circle h5 mb-0 text-primary"></i>
                    </button>
                    <span className="pb-1">
                        {id}
                    </span>
                </td>
                <td>{name}</td>
            </tr>
            <tr className={`collapse ${id}`}>
                <td>Giờ bắt đầu</td>
                <td>
                    <i>{startTime}</i>
                </td>
            </tr>
            <tr className={`collapse ${id}`}>
                <td>Giờ kết thúc</td>
                <td>
                    <i>{endTime}</i>
                </td>
            </tr>
            <tr className={`collapse ${id}`}>
                <td>Trạng thái</td>
                <td>
                    <i>{status ? "Hoạt động" : "Không hoạt động"}</i>
                </td>
            </tr>
            <tr className={`collapse ${id}`}>
                <td>Màu</td>
                <td >
                    <div className="box-color" style={{backgroundColor: `${color}`}}></div>
                </td>
            </tr>
            <tr className={`collapse ${id}`}>
                <td>Tác vụ</td>
                <td>
                    <Link to="/schedule/details">
                        Chỉnh sửa
                    </Link>
                </td>
            </tr>
        </>
    )
}