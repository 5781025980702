import "./index.css";
import {Col, Row} from "reactstrap";
import React from "react";
import TableInfo from "./components/TableInfo";
export default function Branch(){
    return (
        <React.Fragment>
            <div className="page-content">
                <Row className="d-flex flex-row justify-content-between align-items-center">
                    <Col>
                        <div className="page-title-box d-flex align-items-center justify-content-between">
                            <h4 className="page-title mb-0 font-size-18">CHI NHÁNH</h4>
                        </div>
                    </Col>
                    <Col className="text-white d-flex flex-row justify-content-end">
                        <b className="h-auto">Chi nhánh </b>
                        <p className="mb-0"> / Danh sách</p>
                    </Col>
                </Row>
                <div className="card-container">
                    <h5 className="heading">Danh sách</h5>
                    <div className="filter d-flex flex-row justify-content-between align-items-center mb-4">
                        <div className="">
                            <span>Hiện </span>
                            <select className="select-wrapper" name="" id="">
                                <option value="">10</option>
                                <option value="">20</option>
                                <option value="">50</option>
                                <option value="">100</option>
                            </select>
                            <span> hàng</span>
                        </div>
                        <div>
                            <span>Tìm: </span>
                            <input type="text" className="input" />
                        </div>
                    </div>
                    <TableInfo />
                </div>
            </div>
        </React.Fragment>
    )
}