import {Button, Col, Row} from "reactstrap";
import React, {useState} from "react";
import DayoffCard from "./components/dayoff-card";
import {Link} from "react-router-dom";
import "./index.css";
export default function DayOffNew(){
    const [arrayDayOff , setArrayDayOff] = useState([]);
    const [numOfComponent, setNumOffComponent] = useState(1);
    const handleAddDayOff = () => {
        setArrayDayOff([...arrayDayOff, numOfComponent])
    }
    const handleDeleteDayOff = (key) => {
        const afterDayoff = arrayDayOff.filter((item) => {
            return item !== key;
        })
        setArrayDayOff(afterDayoff);
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <Row className="d-flex flex-row justify-content-between align-items-center">
                    <Col>
                        <div className="page-title-box d-flex align-items-center justify-content-between">
                            <h4 className="page-title mb-0 font-size-18">NGÀY NGHỈ</h4>
                        </div>
                    </Col>
                    <Col className="text-white d-flex flex-row justify-content-end">
                        <b className="h-auto">Ngày nghỉ </b>
                        <p className="mb-0"> / Đăng ký</p>
                    </Col>
                </Row>
                <div>
                    {
                        arrayDayOff.map((item) => {
                            // console.log(item);
                            return <DayoffCard id={item} handleDeleteDayOff={(item) => {
                                handleDeleteDayOff(item);
                            }} />
                        })
                    }
                </div>
                <button
                    color="primary"
                    outline
                    className="d-flex flex-row align-items-center w-100 btn-add custom-button"
                    aria-pressed="false"
                    onClick={() => {
                        setNumOffComponent((prevState) => prevState + 1);
                        handleAddDayOff();
                    }}
                >
                    <i className="bx bxs-plus-square h5 mb-0 text-primary mt-0"></i>
                    <span className="ms-2">Đăng ký thêm</span>
                </button>
                <div className="mt-3 d-flex flex-row justify-content-end">
                    <Button
                        color="success"
                        outline
                        className="me-2"
                    >
                        <Link to="/employee/details">
                            Đăng ký
                        </Link>
                    </Button>
                    <Button
                        color="danger"
                        outline
                    >
                        <Link to="/dashboard">
                            Huỷ bỏ
                        </Link>
                    </Button>
                </div>
            </div>
        </React.Fragment>
    )
}