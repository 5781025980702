import "./index.css";
import {Col, Row} from "reactstrap";
import React from "react";
import ListEmployee from "./components/list-employee";
export default function EmployeeList(){
    return (
        <React.Fragment>
            <div className="page-content">
                <Row className="d-flex flex-row justify-content-between align-items-center">
                    <Col>
                        <div className="page-title-box d-flex align-items-center justify-content-between">
                            <h4 className="page-title mb-0 font-size-18">NHÂN SỰ</h4>
                        </div>
                    </Col>
                    <Col className="text-white d-flex flex-row justify-content-end">
                        <b className="h-auto">Nhân sự </b>
                        <p className="mb-0"> / Danh sách</p>
                    </Col>
                </Row>
                <div className="card-container">
                    <h5 className="heading">Danh sách</h5>
                    <div className="filter d-flex flex-row justify-content-between align-items-center mb-4">
                        <div className="">
                            <span>Hiện </span>
                            <select className="select-wrapper" name="" id="">
                                <option value="">10</option>
                                <option value="">20</option>
                                <option value="">50</option>
                                <option value="">100</option>
                            </select>
                            <span> hàng</span>
                        </div>
                        <div>
                            <span>Tìm: </span>
                            <input type="text" className="input" />
                        </div>
                    </div>
                    <div className="filter d-flex flex-row justify-content-between align-items-center mb-4">
                        <div className="w-100 d-flex justify-content-between">
                            <span>Chi nhánh </span>
                            <select className="select-wrapper w-75" name="" id="">
                                <option value="">Tất cả các chi nhánh</option>
                                <option value="">Chi nhánh CN0001</option>
                                <option value="">Chi nhánh CN0009</option>
                                <option value="">Chi nhánh CN0006</option>
                                <option value="">Chi nhánh CN0008</option>
                            </select>
                        </div>
                    </div>
                    <ListEmployee />
                </div>
            </div>
        </React.Fragment>
    )
}