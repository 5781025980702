import "./index.css";
import BranchDetails from "./BranchDetails";
import {Pagination, PaginationItem, PaginationLink} from "reactstrap";
export default function TableInfo() {
    return (
        <div className="table-info panel panel-default">
            <table className="table table-striped table-bordered table-responsive mb-3">
                <thead className="border">
                    <tr>
                        <th style={{width: "40%"}}>Mã chi nhánh</th>
                        <th>Tên chi nhánh</th>
                    </tr>
                </thead>
                <tbody>
                    <BranchDetails id={"CN0001"} name={"Chi nhánh 01"} manager={"Trần Đình Hải"} status={false} />
                    <BranchDetails id={"CN0002"} name={"Chi nhánh 02"} manager={"Kylian Mbappe"} status={true} />
                    <BranchDetails id={"CN0003"} name={"Chi nhánh 03"} manager={"Thích Minh Tuệ"} status={false} />
                    <BranchDetails id={"CN0004"} name={"Chi nhánh 04"} manager={"Trần Đình Hải"} status={false} />
                    <BranchDetails id={"CN0005"} name={"Chi nhánh 05"} manager={"Kylian Mbappe"} status={true} />
                    <BranchDetails id={"CN0006"} name={"Chi nhánh 06"} manager={"Thích Minh Tuệ"} status={false} />
                    <BranchDetails id={"CN0007"} name={"Chi nhánh 07"} manager={"Trần Đình Hải"} status={false} />
                    <BranchDetails id={"CN0008"} name={"Chi nhánh 08"} manager={"Kylian Mbappe"} status={true} />
                    <BranchDetails id={"CN0009"} name={"Chi nhánh 09"} manager={"Thích Minh Tuệ"} status={false} />
                </tbody>
            </table>
            <Pagination
                aria-label="Page navigation example"
                size="sm"
            >
                <PaginationItem>
                    <PaginationLink
                        href="#"
                        previous
                    >
                        Trước
                    </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                    <PaginationLink href="#">
                        1
                    </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                    <PaginationLink href="#">
                        2
                    </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                    <PaginationLink href="#">
                        3
                    </PaginationLink>
                </PaginationItem>
                <PaginationItem>
                    <PaginationLink
                        href="#"
                        next
                    >
                        Sau
                    </PaginationLink>
                </PaginationItem>
            </Pagination>
        </div>
    )
}