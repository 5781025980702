import React, {useState} from "react";
import {Button, Col, Input, Label, Row} from "reactstrap";
import toastr from "toastr";
import {Link} from "react-router-dom";

export default function NewBranch(){
    const [status, setStatus] = useState(false);
    const [name, setName] = useState("");
    const [address, setAddress] = useState("");
    const [username, setUsername] = useState("");
    const [manager,setManager] = useState("");
    const [phone,setPhone] = useState("");
    const [isCheck , setIsCheck] = useState(false);
    return (
        <React.Fragment>
            <div className="page-content">
                <Row className="d-flex flex-row justify-content-between align-items-center">
                    <Col>
                        <div className="page-title-box d-flex align-items-center justify-content-between">
                            <h4 className="page-title mb-0 font-size-18">CHI NHÁNH</h4>
                        </div>
                    </Col>
                    <Col className="text-white d-flex flex-row justify-content-end">
                        <b className="h-auto">Chi nhánh </b>
                        <p className="mb-0"> / Thêm mới</p>
                    </Col>
                </Row>
                <div className="card-container">
                    <h4 className="heading">Chi tiết</h4>
                    <div>
                        <div className="d-flex flex-column">
                            <Label htmlFor="branchName" className="label">Tên chi nhánh</Label>
                            <Input
                                invalid={isCheck}
                                id="branchName"
                                name="branchName"
                                className="input-edit-info"
                                type="text"
                                placeholder="Nhập tên chi nhánh"
                                value={name}
                                onChange={(e) => {
                                    setName(e.target.value);
                                }}
                                required
                            />
                            {isCheck && <p className="message-err">
                                Chưa nhập tên chi nhánh
                            </p>}
                        </div>
                        <div className="d-flex flex-column">
                            <Label htmlFor="address" className="label">Địa chỉ</Label>
                            <Input
                                invalid={isCheck}
                                id="address"
                                name="address"
                                className="input-edit-info input-textarea"
                                type="textarea"
                                placeholder="Nhập địa chỉ chi nhánh"
                                value={address}
                                onChange={(e) => {
                                    setAddress(e.target.value);
                                }}
                                required
                            />
                            {isCheck && <p className="message-err">
                                Chưa nhập địa chỉ chi nhánh
                            </p>}
                        </div>
                        <div className="d-flex flex-column">
                            <Label htmlFor="username" className="label">Tên đăng nhập tài khoản quản lý</Label>
                            <div className="d-flex flex-row justify-content-between align-items-center">
                                <Input
                                    id="username"
                                    className="input-edit-info"
                                    name="username"
                                    type="text"
                                    placeholder="Nhập tên đăng nhập tài khoản"
                                    value={username}
                                    onChange={(e) => {
                                        setUsername(e.target.value);
                                    }}
                                />
                                <Button
                                    className="btn-test"
                                    color="info"
                                    outline
                                >
                                    <span>
                                        Kiểm tra
                                    </span>
                                </Button>
                            </div>
                        </div>
                        <div className="d-flex flex-column">
                            <Label htmlFor="manager" className="label">Họ tên quản lý</Label>
                            <Input
                                id="manager"
                                className="input-edit-info disabled"
                                name="manager"
                                type="text"
                                value={manager}
                                onChange={(e) => {
                                    setManager(e.target.value);
                                }}
                                placeholder="Tự động nhập từ tên đăng nhập"
                                disabled
                            />
                        </div>
                        <div className="d-flex flex-column">
                            <Label htmlFor="phone" className="label">Số điện thoại quản lý</Label>
                            <Input
                                id="phone"
                                className="input-edit-info disabled"
                                name="phone"
                                type="text"
                                value={phone}
                                onChange={(e) => {
                                    setPhone(e.target.value);
                                }}
                                placeholder="Tự động nhập từ tên đăng nhập"
                                disabled
                            />
                        </div>
                        <div>
                            <Label htmlFor="status" className="label">Trạng thái</Label>
                            <div className="d-flex flex-row align-items-center">
                                <div className="form-switch me-1">
                                    <input
                                        id="status"
                                        className="form-check-input"
                                        name="status"
                                        type="checkbox"
                                        role="switch"
                                        id="switchCheckChecked"
                                        checked={status}
                                        onChange={() => setStatus(!status)}
                                        required
                                    />
                                </div>
                                {
                                    status ?
                                        (
                                            <span>
                                                (Hoạt động)
                                            </span>
                                        ) : (
                                            <span>
                                                (Không hoạt động)
                                            </span>
                                        )
                                }

                            </div>
                        </div>
                    </div>
                    <div className="mt-3 d-flex flex-row justify-content-end">
                        <Button
                            color="success"
                            outline
                            className="me-2"
                        >
                            <Link to="/branch/details">
                                Thêm mới
                            </Link>
                        </Button>
                        <Button
                            color="danger"
                            outline
                        >
                            <Link to="/dashboard">
                                Huỷ bỏ
                            </Link>
                        </Button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}