import avatar2 from "../../../../assets/images/users/avatar-2.jpg";
import React from "react";
import "./index.css";
export default function Info(){
    return (
        <div className="card-info d-flex flex-row justify-content-around">
            <div className="user-img">
                <img src={avatar2} alt="" className="avatar-lg rounded-circle w-[100px] h-[100px] avatar-manager" />
            </div>
            <div>
                <h4 className="fs-4 fw-bolder">Nguyễn Anh Long</h4>
                <div>
                    <i className="mdi mdi-file-phone me-1 solid h5"></i>
                    <span className="opacity-30">0987654321</span>
                </div>
                <div>
                    <i className="mdi mdi-calendar me-1 solid h5"></i>
                    <span className="opacity-30">30/01/1980</span>
                </div>
                <div>
                    <i className="mdi mdi-smart-card-outline me-1 solid h5"></i>
                    <span className="opacity-30">00123456789</span>
                </div>
            </div>
        </div>
    )
}