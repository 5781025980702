import React from "react"
import { Row } from "reactstrap"
import Overview from "./components/Overview";
import Info from "./components/Info/Info";

const Dashboard = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          <div className="col-12">
            <div className="page-title-box d-flex align-items-center justify-content-between">
              <h4 className="page-title mb-0 font-size-18">TRANG CHỦ</h4>
              <p className="text-white-50 fw-light">Trang chủ</p>
            </div>
          </div>
        </Row>
        <Row className="d-flex flex-row ">
          <Info />
          <Overview />
        </Row>

      </div>
    </React.Fragment>
  )
}

export default Dashboard