import "./index.css";
import React, {useState} from "react";
import {Button, Col, Input, Label, Row} from "reactstrap";
import {Link} from "react-router-dom";

export default function EmPloyeeNew() {
    const [branchName, setBranchName] = useState(null);
    const [address, setAddress] = useState(null);
    const [username, setUsername] = useState(null);
    const [status, setStatus] = useState(false);
    const [birthDay, setBirthDay] = useState(null);
    const [employeeName, setEmployeeName] = useState(null);
    const [userId, setUserId] = useState(null);
    const [phone, setPhone] = useState(null);
    const [role, setRole] = useState(null);
    const [subRole, setSubRole] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [frontCard, setFrontCard] = useState(null);
    const [behindCard, setBehindCard] = useState(null);
    const [avatar, setAvatar] = useState(null);
    const [isCheck , setIsCheck] = useState(false);

    return (
        <React.Fragment>
            <div className="page-content">
                <Row className="d-flex flex-row justify-content-between align-items-center">
                    <Col>
                        <div className="page-title-box d-flex align-items-center justify-content-between">
                            <h4 className="page-title mb-0 font-size-18">NHÂN VIÊN</h4>
                        </div>
                    </Col>
                    <Col className="text-white d-flex flex-row justify-content-end">
                        <b className="h-auto">Nhân viên </b>
                        <p className="mb-0"> / Thêm mới</p>
                    </Col>
                </Row>
                <div className="card-container">
                    <h4 className="heading">Chi tiết</h4>
                    <div>
                        <div className="d-flex flex-column">
                            <Label htmlFor="id" className="label">Mã nhân viên</Label>
                            <Input id="id" name="id" className="input-edit-info disabled" type="text" value="NV123456"
                                   disabled required/>
                        </div>
                        <div className="d-flex flex-column">
                            <Label htmlFor="employeeName" className="label">Tên nhân viên</Label>
                            <Input invalid={isCheck} id="employeeName" name="employeeName" placeholder="Nhập họ tên nhân viên"
                                   className="input-edit-info" type="text"
                                   onChange={(e) => setEmployeeName(e.target.value)} value={employeeName} required/>
                            {isCheck && <p className="message-err">
                                Chưa nhập họ tên nhân viên
                            </p>}
                        </div>
                        <div className="d-flex flex-column">
                            <Label htmlFor="avatar" className="label">Ảnh đại diện</Label>
                            {
                                avatar ? <img src={avatar} alt="avatar" className="avatar"/> : <div className="avatar ">
                                    <span>Chưa có ảnh</span>
                                </div>
                            }
                            <input
                                type="file"
                                accept="image/*"
                                id="avatarImg"
                                className="form-control mt-2 form-control-sm"
                                onChange={(e) => {
                                    const [file] = e.target.files;
                                    if (file) {
                                        const url = URL.createObjectURL(file);
                                        setAvatar(url);
                                    }
                                }}
                            />
                        </div>
                        <div className="d-flex flex-column">
                            <Label htmlFor="birthDay" className="label">Ngày sinh</Label>
                            <Input
                                id="birthDay"
                                className="input-edit-info form-control"
                                name="birthDay"
                                type="date"
                                value={birthDay}
                                onChange={(e) => setBirthDay(e.target.value)}
                            />
                        </div>
                        <div className="d-flex flex-column">
                            <Label htmlFor="userId" className="label">Số CCCD</Label>
                            <Input invalid={isCheck} placeholder="Nhập số CCCD" id="userId" className="input-edit-info" name="userId" type="text"
                                   value={userId} onChange={(e) => setUserId(e.target.value)}/>
                            {isCheck && <p className="message-err">
                                Chưa nhập số CCCD
                            </p>}
                        </div>
                        <div className="d-flex flex-column">
                            <Label htmlFor="userIdCard" className="label">Ảnh chụp CCCD</Label>
                            <span className="sub-label">Mặt trước:</span>
                            {
                                frontCard ? <img src={frontCard} alt="abc"/> : <div className="card-userId"><span>Chưa có ảnh</span></div>
                            }
                            <input
                                className="form-control mt-2 form-control-sm"
                                id="formFileSm"
                                type="file"
                                accept="image/*"
                                onChange={(e) => {
                                    const [file] = e.target.files;
                                    if (file) {
                                        const url = URL.createObjectURL(file);
                                        setFrontCard(url);
                                    }
                                }}
                            />
                            <span className="sub-label mt-2 object-fit-cover">Mặt sau:</span>
                            {
                                frontCard ? <img src={behindCard} alt="abc"/> : <div className="card-userId"><span>Chưa có ảnh</span></div>
                            }
                            <input
                                className="form-control mt-2 form-control-sm"
                                id="formFileSm"
                                type="file"
                                accept="image/*"
                                onChange={(e) => {
                                    const [file] = e.target.files;
                                    if (file) {
                                        const url = URL.createObjectURL(file);
                                        setBehindCard(url);
                                    }
                                }}
                            />
                        </div>
                        <div className="d-flex flex-column">
                            <Label htmlFor="phone" className="label">Số điện thoại</Label>
                            <Input invalid={isCheck} placeholder="Nhập số điện thoại" id="phone" className="input-edit-info" name="phone" type="text" value={phone}
                                   onChange={(e) => setPhone(e.target.value)}/>
                            {isCheck && <p className="message-err">
                                Chưa nhập số điện thoại
                            </p>}
                        </div>
                        <div className="d-flex flex-column">
                            <Label htmlFor="address" className="label">Quê quán</Label>
                            <Input invalid={isCheck} placeholder="Nhập quê quán" id="address" className="input-edit-info input-textarea" name="address"
                                   type="textarea" value={address}
                                   onChange={(e) => setAddress(e.target.value)}/>
                            {isCheck && <p className="message-err">
                                Chưa nhập quê quán
                            </p>}
                        </div>
                        <div className="d-flex flex-column">
                            <Label htmlFor="role" className="label">Chức vụ</Label>
                            <select placeholder="Chọn chức vụ" className="form-select" aria-label="Default select example"
                                    onChange={(e) => setRole(e.target.value)}>
                                <option value="Quản lý">Quản lý</option>
                                <option value="Nhân viên">Nhân viên</option>
                                <option value="Lễ tân">Lễ tân</option>
                                <option value="Bảo vệ">Bảo vệ</option>
                            </select>
                        </div>
                        <div className="d-flex flex-column">
                            <Label htmlFor="subRole" className="label">Vị trí</Label>
                            <Input invalid={isCheck} placeholder="Nhập vị trí" id="subRole" className="input-edit-info" name="subRole" type="text"
                                   value={subRole} onChange={(e) => setSubRole(e.target.value)}/>
                            {isCheck && <p className="message-err">
                                Chưa nhập vị trí
                            </p>}
                        </div>
                        <div className="d-flex flex-column">
                            <Label htmlFor="branchName" className="label">Chi nhánh</Label>
                            <select placeholder="Chọn chi nhánh" className="form-select" aria-label="Default select example"
                                    onChange={(e) => setBranchName(e.target.value)}>
                                <option value="Chi nhánh CN0001">Chi nhánh CN0001</option>
                                <option value="Chi nhánh CN0001">Chi nhánh CN0002</option>
                                <option value="Chi nhánh CN0001">Chi nhánh CN0003</option>
                            </select>
                        </div>
                        <div className="d-flex flex-column">
                            <Label htmlFor="startDate" className="label">Ngày bắt đầu làm việc</Label>
                            <Input id="startDate" className="input-edit-info form-control" name="startDate"
                                   type="date" value={startDate}
                                   onChange={(e) => setStartDate(e.target.value)}/>
                        </div>
                        <div className="d-flex flex-column">
                            <Label htmlFor="username" className="label">Tên tài khoản đăng nhập</Label>
                            <Input invalid={isCheck} placeholder="Nhập tên đăng nhập" id="username" className="input-edit-info" name="username" type="text"
                                   value={username} onChange={(e) => setUsername(e.target.value)}/>
                            {isCheck && <p className="message-err">
                                Chưa nhập tên đăng nhập
                            </p>}
                        </div>
                        <div className="d-flex flex-column">
                            <Label htmlFor="password" className="label">Mật khẩu *</Label>
                            <Input invalid={isCheck} aria-placeholder="Nhập mật khẩu" id="password" className="input-edit-info" name="password" type="text"
                                   placeholder="Nhập mật khẩu mới"/>
                            {isCheck && <p className="message-err">
                                Chưa nhập mật khẩu
                            </p>}
                        </div>
                        <div className="d-flex flex-column">
                            <Label htmlFor="password" className="label">Nhập lại mật khẩu *</Label>
                            <Input invalid={isCheck} aria-placeholder="Nhập lại mật khẩu" id="password" className="input-edit-info" name="password" type="text"
                                   placeholder="Nhập lại mật khẩu mới"/>
                            {isCheck && <p className="message-err">
                                Chưa nhập lại mật khẩu
                            </p>}
                        </div>
                        <div>
                            <Label htmlFor="status" className="label">Trạng thái</Label>
                            <div className="d-flex flex-row align-items-center">
                                <div className="form-switch me-1">
                                    <input id="status" className="form-check-input" name="status" type="checkbox"
                                           role="switch" id="switchCheckChecked" checked={status}
                                           onChange={() => setStatus(!status)} required/>
                                </div>
                                {
                                    status ?
                                        (
                                            <span className="text-success">
                                                Đang làm
                                            </span>
                                        ) : (
                                            <span className="text-danger">
                                                Đã nghỉ
                                            </span>
                                        )
                                }

                            </div>
                        </div>
                    </div>
                    <div className="mt-3 d-flex flex-row justify-content-end">
                        <div className="mt-3 d-flex flex-row justify-content-end">
                            <Button
                                color="success"
                                outline
                                className="me-2"
                            >
                                <Link to="/employee/details">
                                    Thêm mới
                                </Link>
                            </Button>
                            <Button
                                color="danger"
                                outline
                            >
                                <Link to="/dashboard">
                                    Huỷ bỏ
                                </Link>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}